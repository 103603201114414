@import './resources/css/bootstrap.css';
@import "mixins.scss";
$form-width: 80%;
$max_form_width: 460px;
$contrast_color: #EA0029;
$top_bar_height: calc(max(6%, 55px));
$bottom_bar_height: calc(max(6%, 60px));
$max_controls_width: 800px;
$carousel_height: calc(100% - #{$top_bar_height} - #{$bottom_bar_height});
$local_stream_width: calc(max(80px, 8vw));
$control_bar_color: #272727;
$control_icon_size: 33px;
.App {
    height: 100vh;
    width: 100vw;
    padding: 0px;
    margin: 0px;
    text-align: center;
}

input.form-control {
    padding: 10px 16px;
}

.h-90 {
    height: 90% !important;
}

.btn {
    cursor: pointer;
}

.btn.btn-primary {
    background-color: #04141F;
    border-color: darken($color: #05141F, $amount: 1);
}

.btn.btn-link.inline {
    padding: 0px;
    vertical-align: baseline;
    line-height: inherit;
}

.btn.btn-link.custom {
    color: #05141F;
    text-decoration: none;
    font-weight: bold;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

.sr-only {
    display: none;
}

.modal-header {
    .close {
        padding: .5rem;
        margin: -.5rem -.5rem -.5rem auto;
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: .25em;
        color: #000;
        background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
        border: 0;
        border-radius: .25rem;
        opacity: .5;
        span {
            display: none;
        }
        &:hover {
            color: #000;
            text-decoration: none;
            opacity: .75;
        }
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
            opacity: 1;
        }
    }
}

.center {
    @include center;
}

.form-check:not(.form-switch) {
    .form-check-input[type=checkbox] {
        &:focus {
            border-color: #04141F;
            box-shadow: 0 0 0 0.25rem rgba(4, 20, 31, 0.25);
        }
        &:checked {
            background-color: #04141F;
            border-color: #04141F;
        }
    }
}

.icon {
    cursor: pointer;
    padding: 5px;
    flex-grow: 1;
    // width: 60px;
    max-width: 150px;
    box-sizing: border-box;
    &:hover {
        opacity: 0.7;
        background-color: rgb(32, 32, 32);
    }
}

.camera-change-icon {
    display: none;
}

.password-input-page {
    width: 100%;
    height: 100%;
    @include center;
    background: url("./resources/images/bg_landscape.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .password-input-form {
        width: $form-width;
        max-width: $max_form_width;
        background-color: #ffffff99;
        padding: 45px 30px;
        backdrop-filter: blur(3px);
    }
}

.form-control {
    border-color: lighten(black, 50%);
    &:focus {
        border-color: black;
        box-shadow: 0 0 0 0.25rem lighten(black, 85%);
    }
}

.rounded-rect {
    border-radius: 21px;
    // background-color: #04141F;
    padding: 10px 30px;
    color: white;
    .join-settings {
        .form-switch {
            margin-bottom: 0px;
        }
        @include center;
        flex-direction: row;
        .form-check-input {
            border-color: black;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 1%29'/%3e%3c/svg%3e");
            &:focus {
                border-color: black;
                box-shadow: 0 0 0 0.25rem lighten(black, 85%);
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 1%29'/%3e%3c/svg%3e")
            }
            &:checked {
                background-color: black;
                border-color: black;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
            }
        }
    }
    .audio-icon,
    .video-icon {
        path {
            fill: lighten(black, 20%);
        }
        line {
            stroke: lighten(black, 20%);
        }
    }
    .audio-icon {
        margin-right: 0.5rem;
    }
    .video-icon {
        margin-right: 0.75rem;
    }
}

.access-required-page {
    width: 100%;
    height: 100%;
    @include center;
    background-color: #f7f7f8;
    padding: 10px;
    .cancel-access-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-weight: normal
    }
    .group-meeting-infographic {
        @include center;
        flex-grow: 1;
        max-width: 100%;
        max-height: 30%;
        svg {
            // width: 60%;
            // height: auto;
            width: 100%;
            height: 100%;
            max-width: $max_form_width;
        }
    }
    .access-required-form {
        width: $form-width;
        max-width: $max_form_width;
        margin-bottom: 1rem;
        .terms-and-record-form {
            .form-check {
                text-align: left;
            }
        }
    }
}

$video-stream-border: 0.5px solid white;
.conference-page {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    .video-carousel {
        height: $carousel_height;
        background-color: white;
        // border: $video-stream-border;
        box-sizing: border-box;
        border-bottom: 0px;
    }
    .controls-bar-1,
    .controls-bar-2 {
        @include center;
        width: 100%;
        color: white;
        z-index: 2;
        .controls {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            // justify-content: space-evenly;
            justify-content: center;
            overflow: hidden;
        }
        svg {
            width: $control_icon_size;
            height: $control_icon_size;
        }
    }
    .controls-bar-1 {
        position: fixed;
        top: 0px;
        height: $top_bar_height;
        background-color: $control_bar_color;
        .left-controls,
        .right-controls,
        .conference-kia-branding {
            height: 100%;
        }
        .left-controls,
        .right-controls {
            width: 33%;
            flex: 33%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
        .left-controls {
            justify-content: flex-start;
        }
        .right-controls {
            width: 36%;
            flex: 36%;
            justify-content: flex-end;
            .icon {
                margin-right: 25px;
                cursor: default;
                &:hover {
                    opacity: 1;
                    background-color: $control_bar_color;
                }
            }
        }
        .conference-kia-branding {
            @include center;
            width: 40%;
            flex: 40%;
            flex-grow: 1;
        }
        svg {
            width: 20px;
            height: 20px;
        }
        .controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // max-width: $max_controls_width;
        }
    }
    .controls-bar-2 {
        position: fixed;
        bottom: 0px;
        height: $bottom_bar_height;
        background-color: $control_bar_color;
        .mic-icon {
            transform: translateY(3px);
        }
        .icon-with-label {
            position: relative;
            @include center;
            font-size: 0.6rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            .count {
                position: absolute;
                top: 3px;
                right: 48%;
                transform: translateX(calc(#{$control_icon_size} / 2));
                &.notification {
                    background-color: $contrast_color;
                    color: white;
                    border-radius: 50%;
                    height: 1rem;
                    width: 1rem;
                    display: inline-block;
                    @include center;
                }
            }
        }
        .controls {
            max-width: $max_controls_width;
        }
    }
    .end-call-btn {
        background-color: $contrast_color;
        padding: .05rem 1rem;
    }
}

.participants-page {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    .participant-row {
        display: flex;
        .participant-image {
            width: 40px;
            height: 40px;
            border-radius: 4px;
        }
        .participant-name {
            display: flex;
            align-items: center;
            flex-grow: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0px 10px;
            font-size: large;
        }
        .participant-status {
            display: flex;
            width: 20%;
            align-items: center;
            justify-content: space-evenly;
            .participant-status-icon {
                @include center;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .participants-heading {
        position: relative;
        box-sizing: border-box;
        padding: 20px 20px 0px 20px;
        font-weight: bold;
        .close-participants-btn {
            position: absolute;
            left: 15px;
            top: 15px;
        }
    }
}

.video-streams {
    position: fixed;
    top: $top_bar_height;
    bottom: $bottom_bar_height;
    height: $carousel_height;
    width: 100%;
    // height: 100%;
    .local-stream-viewer-toggle {
        position: absolute;
        right: 3px;
        bottom: 30px;
        height: calc(#{$local_stream_width} * 1.2);
        border: 0.5px solid #e4e4e4;
        padding: 4px;
        z-index: 2;
    }
    .current-user-stream-container {
        position: absolute;
        right: 35px;
        bottom: 30px;
        width: $local_stream_width;
        height: calc(#{$local_stream_width} * 1.2);
        background-color: black;
        border: 1px solid white;
        z-index: 2;
        text-align: left;
        @include center;
        flex-direction: row;
        border-radius: 5px;
        overflow: hidden;
        .placeholder-stream-container {
            width: 100%;
            height: 100%;
            background-color: #DBDBDB;
            @include center;
            svg {
                max-width: 50%;
            }
        }
        .participant-stream-label {
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
            padding: 10px;
            color: white;
            text-align: left;
        }
        .current-user-stream-label {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 4px;
            left: 4px;
            color: white;
            font-size: 0.8rem;
        }
        .current-user-stream-image,
        .current-user-stream-video {
            width: 100%;
            height: auto;
            margin: auto;
            transform: rotateY(180deg);
            // height: 100%;
            // max-width: 100%;
            // max-height: 100%;
            // border-radius: 5px;
        }
    }
    .carousel-root {
        width: 100%;
        height: 100%;
        user-select: none;
        .carousel {
            width: 100%;
            height: 100%;
            &.carousel-slider .control-arrow {
                z-index: 1;
                width: 50px;
            }
            .control-dots {
                background-color: #000000;
                margin: 0px;
                // border: 1px solid black;
                // border-top: $video-stream-border;
                .dot {
                    background-color: white;
                    opacity: 1;
                    box-shadow: none;
                    &.selected {
                        background-color: $contrast_color;
                    }
                }
            }
            .slider-wrapper {
                width: 100%;
                height: 100%;
                .slider {
                    width: 100%;
                    height: 100%;
                    .slide {
                        .participant-stream-container {
                            width: 100%;
                            height: 100%;
                            background-color: black;
                            overflow: hidden;
                            @include center;
                            .participant-stream {
                                height: 100%;
                                width: auto;
                            }
                        }
                        .placeholder-stream-container {
                            width: 100%;
                            height: 100%;
                            background-color: #DBDBDB;
                            @include center;
                            svg {
                                width: 80px;
                                height: 80px;
                                max-width: 50%;
                            }
                        }
                        .participant-stream-label {
                            position: absolute;
                            width: 100%;
                            top: 0px;
                            left: 0px;
                            padding: 10px;
                            color: white;
                            text-align: left;
                            background-image: linear-gradient(#000000, #00000000);
                        }
                    }
                }
            }
        }
    }
}

.custom-toast-container {
    position: relative;
    display: inline-block;
    background-color: transparent;
    min-height: 32px;
    white-space: nowrap;
    overflow: visible;
    padding: 0px !important;
    box-shadow: none;
    // @include center;
    width: auto;
    margin-bottom: 10px;
    border: 1px solid #dadada;
    .toast-close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .Toastify__toast-body {
        margin: auto;
        text-align: center;
        display: inline-block;
        padding: 0px;
        // @include center;
        // transform: translateX(50%);
        .toast-content {
            background-color: white;
            color: black;
            padding: 15px 20px;
            &.dark {
                background-color: #05141F;
                color: white;
            }
        }
        .success-toast {
            background-color: #52E13C;
            color: white;
        }
        .error-toast {
            background-color: rgb(238, 48, 48);
            color: white;
        }
    }
}

.messages-page {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    .file-message {
        position: relative;
        background-color: #EAEAEA;
        display: flex;
        align-items: center;
        width: 200px;
        max-width: 100%;
        padding: 4px 20px 4px 4px;
        border-radius: 7px;
        cursor: pointer;
        margin-top: 5px;
        .delete-file-message {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
        .attached-file-icon {
            width: 20px;
            height: 20px;
            transform: rotate(-45deg);
            margin-left: 5px;
        }
        .file-meta {
            width: 180px;
            max-width: calc(100% - 20px);
            text-decoration: none !important;
            color: black;
            text-align: left;
            .file-name {
                font-size: 1rem;
                font-weight: bold;
                margin-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .file-size {
                font-size: 0.8rem;
                margin-left: 5px;
            }
        }
    }
    .messages-page-heading {
        position: relative;
        box-sizing: border-box;
        padding: 20px 20px 0px 20px;
        font-weight: bold;
        height: 60px;
        .close-messages-btn {
            position: absolute;
            left: 15px;
            top: 15px;
        }
    }
    .messages-container-wrapper {
        height: calc(100% - 60px - 80px);
        overflow-y: auto;
        @include custom-scroll;
        .messages-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            // flex-grow: 1;
            padding: 10px;
            .peer-message-bubble {
                @include msg-bubble;
                background-color: white;
                color: #05141F;
                border-radius: 5px;
                align-self: flex-start;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            }
            .current-user-message-bubble {
                @include msg-bubble;
                background-color: #05141FCC;
                color: white;
                border-radius: 10px;
                align-self: flex-end;
            }
        }
    }
    .compose-message {
        background-color: #05141F08;
        min-height: 80px;
        .compose-message-form {
            display: flex;
            align-items: center;
            padding: 15px;
            margin-bottom: 10px;
            .message-input-container {
                position: relative;
                width: 100%;
                .message-input {
                    width: 100%;
                    padding-right: 30px;
                    word-break: break-word;
                }
                .attachment-icon {
                    position: absolute;
                    right: 7px;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.share-screen-popup-container {
    background-color: white;
    color: #000;
    .share-screen-controls {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 10px;
    }
}

.ending-call-popup,
.joining-room-popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.joining-room-popup-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0px;
}

.joining-room-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (min-width:768px) {
    @keyframes slide_from_right {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0%);
        }
    }
    .participants-page {
        left: auto;
        top: $top_bar_height;
        right: 0px;
        max-width: 400px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-height: calc(100% - #{$top_bar_height} - #{$bottom_bar_height});
        animation: slide_from_right 0.2s ease;
        .close-participants-btn {
            display: none;
        }
        .participants-page-heading {
            text-align: left;
        }
        .close-participants-icon {
            display: block !important;
            position: absolute;
            right: 25px;
            top: 30px;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
    .messages-page {
        left: auto;
        top: $top_bar_height;
        right: 0px;
        max-width: 400px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-height: calc(100% - #{$top_bar_height} - #{$bottom_bar_height});
        animation: slide_from_right 0.2s ease;
        .close-messages-btn {
            display: none;
        }
        .messages-page-heading {
            text-align: left;
        }
        .close-messages-icon {
            display: block !important;
            position: absolute;
            right: 25px;
            top: 30px;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
}

.instruction-modal {
    .padlock-icon {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        margin: 0px 3px;
    }
}

@media screen and (max-width:768px) and (orientation:portrait) {
    .camera-change-icon {
        display: block;
    }
    .password-input-page {
        background-image: url("./resources/images/bg.png");
    }
    .conference-page {
        .controls-bar-1 {
            .left-controls,
            .right-controls {
                justify-content: space-evenly;
                .icon {
                    margin-right: 0px;
                }
            }
        }
    }
    .video-streams {
        .current-user-stream-container {
            .current-user-stream-video {
                border-radius: 5px;
            }
        }
        .carousel-root {
            .carousel {
                .slider-wrapper {
                    .slider {
                        .slide {
                            .participant-stream-container {
                                .participant-stream {
                                    // height: 100%;
                                    // width: auto;
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}