@mixin custom-scroll {
    @media screen and (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 10px;
            height: 8px;
            min-height: 20px;
            background-color: #f7f7f7;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #c9c9c9;
            opacity: 0.7;
            border-radius: 3px;
            min-height: 20px;
            min-width: 20px;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.7;
        }
    }
}

@mixin custom-scroll-thin {
    @media screen and (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            min-height: 5px;
            // background-color: #424242;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a5a5a5;
            opacity: 0.7;
            border-radius: 3px;
            min-height: 4px;
            min-width: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            opacity: 0.7;
        }
    }
}

@mixin center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin msg-bubble {
    max-width: 70%;
    padding: 4px 16px;
    min-width: 100px;
    font-size: 1rem;
    margin-bottom: 15px;
    word-break: break-word;
    .msg-author {
        font-size: 0.8rem;
    }
    .msg-author,
    .msg-body {
        text-align: left;
        margin-bottom: 3px;
    }
    p {
        margin-bottom: 3px;
    }
}